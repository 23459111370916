import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {isParticipantTheScrumMaster} from "../../Utils/Utils";

function Row({ row }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow
                onClick={() => setOpen(!open)}
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    '&:hover': { backgroundColor: '#bfbfbf' },
                    // backgroundColor: '#f5f5f5', // Sets a light background color for the row
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
            >
                <TableCell sx={{ width: '50px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        sx={{ color: '#333' }}
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent the row click from firing
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left" sx={{ width: '75px' }}>
                    {row.url}
                </TableCell>
                <TableCell align="center" >
                    {row.estimateSessionType}
                </TableCell>
                <TableCell align="center">
                    {row.creationDate ? new Date(row.creationDate).toLocaleDateString() : '-'}
                </TableCell>
            </TableRow>

            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 1,
                                width: '75%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                padding: 2,
                                backgroundColor: '#666280',
                                borderRadius: '8px',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div">
                                Estimated Items
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Item Name</TableCell>
                                        <TableCell align="center">Item Estimate</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.itemList.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell align="center">{item.name}</TableCell>
                                            <TableCell align="center">{item.estimate ? item.estimate : "-"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box
                            sx={{
                                margin: 1,
                                width: '75%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                padding: 2,
                                backgroundColor: '#666280',
                                borderRadius: '8px',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div">
                                Estimators
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Estimator Name</TableCell>
                                        <TableCell align="center">Estimator Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.participantList.map((participant) => (
                                        <TableRow key={participant.id}>
                                            <TableCell align="center">{participant.name}</TableCell>
                                            <TableCell align="center">{isParticipantTheScrumMaster(participant.role) ?'Scrum Master': 'Team Member' }</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>





        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        estimateSessionType: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        itemList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                estimate: PropTypes.string,
            })
        ).isRequired,
        participantList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                role: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default function EstimateSessionHistoryCollapsibleTable({ estimateSessions }) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="left" sx={{fontWeight: 'bold'}}>Estimate Session ID</TableCell>
                        <TableCell align="center" sx={{fontWeight: 'bold'}}>Type of Estimate Session</TableCell>
                        <TableCell align="center" sx={{fontWeight: 'bold'}}>Estimate Session Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {estimateSessions.map((row) => (
                        <Row key={row.url} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

EstimateSessionHistoryCollapsibleTable.propTypes = {
    estimateSessions: PropTypes.arrayOf(
        PropTypes.shape({
            estimateSessionType: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            itemList: PropTypes.array.isRequired,
            participantList: PropTypes.array.isRequired,
        })
    ).isRequired,
};
