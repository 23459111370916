import { Box, Typography } from "@mui/material";

const ComingSoonTag = ({theme}) => (
  <Box sx={{
    bgcolor: theme === 'dark' ? '#9747FF' : 'white', 
    display: 'flex',
    width: {xs: '200px', md: '250px'},
    height: {xs: '23px', md: '30px'},
    textTransform: 'uppercase',
    fontWeight: 700,
    justifyContent: 'center',
    fontSize: {xs: '12px', md: '16px'},
    pt: 0.3,
    borderWidth: '2px',
    borderColor: '#BB86FC',
    borderRadius: '10px',
    borderStyle: 'solid',
    alignItems: 'center'
    }}>
      <Typography component="p" variant="p" sx={{color: theme === 'dark' ? 'white' : '#9747FF', textAlign: 'center', fontFamily: `'Roboto', sans-serif`}}>Available on Premium Plan</Typography>
  </Box>
);

export default ComingSoonTag;