import React from 'react';

const MessageParser = ({ children, actions }) => {
    const parse = (message) => {
        if (message.includes('test')) {
            // actions.handleTest();
        }
        if (message.toLowerCase() === 'help') {
            actions.handleHelp();
        }
        else {
            actions.handleDefault();
        }
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions: {},
                });
            })}
        </div>
    );
};

export default MessageParser;