import axios from 'axios';
import SystemConfig from "../config/SystemConfig";

let qs = require('qs');

const baseURL = SystemConfig.getBaseUrl() + '/api';

export const api = axios.create({
  baseURL,
  timeout: SystemConfig.AXIOS_TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'brackets'}),
  withCredentials: true,
});

// Add a request interceptor to include the JWT token in every request
api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('jwtToken'); // Get the token from localStorage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Attach the token to the Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // Token expired or unauthorized, redirect to login
        localStorage.removeItem('jwtToken'); // Clear the token
        window.location.href = '/login'; // Redirect to login page
      }
      return Promise.reject(error);
    }
);
