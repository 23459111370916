import React from "react";

import { Button, Grid, Typography } from "@mui/material";

import EstimateService from "../../service/EstimateService";

export default function EstimateButtons({
  scaleValue,
  giveEstimate,
  selectedEstimate,
}) {
  const scaleButtons = EstimateService.getScaleToUse(scaleValue).valuesMap;

  return (
    <Grid container sx={{ ml: 2, mt: { xs: 1, md: 10 } }}>
      {scaleButtons.map((row) => (
        <Grid item sx={{ mx: 1, my: 1 }} key={row.value}>
          <Button
            variant="contained"
            sx={{ minWidth: "97px", minHeight: "97px" }}
            color={row.label === selectedEstimate ? "success" : "secondary"}
            size="large"
            onClick={() => {
              giveEstimate(row.label);
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 600 }} color="success">
              {row.label}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
