import React, { useContext } from 'react';
import YouTube from 'react-youtube';
import { store } from '../../store';

const YouTubePlayer = ({video}) => {
  if (video === null) {
    // video = {url: 'KXMA-1QKO0c', playedSeconds: 0};
  }
  
  const { dispatch } = useContext(store);

  const checkElapsedTime = (e) => {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();
    if (currentTime > duration * 0.75 ) {
      dispatch({type: 'MARK_AS_SEEN', id: video.id});
    } else if (video.seen && currentTime < duration * 0.75) {
      dispatch({type: 'MARK_AS_NOT_SEEN', id: video.id});
    }
    dispatch({ type: 'UPDATE_TIME', id: video.id, seconds: currentTime});
  };

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      start: video ? video.playedSeconds : undefined,
      rel: 0,
    },
    width: '100%',
    height: '100%'
  };

  return (
      video ? (
          <YouTube
              style={{ width: '90%', height: '60%', display: 'block' }}
              className='player-style'
              videoId={video.url}
              onStateChange={(e) => checkElapsedTime(e)}
              opts={opts}
          />
      ) : null
  );
}

export default YouTubePlayer;