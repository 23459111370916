import { createContext, useReducer } from "react";
const initialState = {
  "courses": {
    "sections": [
      { 
        "id": '1',
        "name": "Introduction to the course" 
      },
      {
        "id": '2',
        "name": "General notions about the estimates" 
      },
      {
        "id": '3',
        "name": "Estimates in Agile"
      },
      {
        "id": '4',
        "name": "Communicating the estimates"
      }
    ],
    "videos": {
      5:  {
          'id': '5',
          'sectionId': '1',
          'title': 'Introduction to the course',
          'url': 'KXMA-1QKO0c',
          'playedSeconds': 0,
          'seen': false
        },
      6: {
          'id': '6',
          'sectionId': '2',
          'title': 'Cone of uncertainty',
          'url': 'N2LAhhw_-eQ',
          'playedSeconds': 0,
          'seen': false
        },
      7: {
          'id': '7',
          'sectionId': '2',
          'title': 'Who does the estimation',
          'url': 'igKmjCSRWak',
          'playedSeconds': 0,
          'seen': false
        },
      8: {
          'id': '8',
          'sectionId': '2',
          'title': 'Types of estimates',
          'url': 'ijyj6UyasKo',
          'playedSeconds': 0,
          'seen': false
        },
      9: {
          'id': '9',
          'sectionId': '2',
          'title': 'Critical Path Method',
          'url': 'ayUIbnF8uU4',
          'playedSeconds': 0,
          'seen': false
        },
      10: {
          'id': '10',
          'sectionId': '3',
          'title': 'Introduction to Scrum',
          'url': 'prNOrNLqoNM',
          'playedSeconds': 0,
          'seen': false
        },
      11: {
          'id': '11',
          'sectionId': '3',
          'title': 'The problem with time estimation',
          'url': 'OLkC6kazvns',
          'playedSeconds': 0,
          'seen': false
        },
      12: {
          'id': '12',
          'sectionId': '3',
          'title': 'Estimating in Story Points',
          'url': '0RO87iSDyHU',
          'playedSeconds': 0,
          'seen': false
        },
      13: {
          'id': '13',
          'sectionId': '3',
          'title': 'Estimation techniques: Complexity Buckets',
          'url': 'vJStJ3fvn8k',
          'playedSeconds': 0,
          'seen': false
        },
      14: {
          'id': '14',
          'sectionId': '3',
          'title': 'Estimation techniques: Planning Poker',
          'url': 'TZajHnZbFXU',
          'playedSeconds': 0,
          'seen': false
        },
      15: {
          'id': '15',
          'sectionId': '3',
          'title': 'Estimation techniques: White Elephant Sizing',
          'url': 'DqO43RiwArw',
          'playedSeconds': 0,
          'seen': false
        },
      16: {
          'id': '16',
          'sectionId': '3',
          'title': 'Estimation techniques: Ouija Board estimating',
          'url': 'RZr3ROEeZfI',
          'playedSeconds': 0,
          'seen': false
        },
      17: {
          'id': '17',
          'sectionId': '3',
          'title': 'Full project estimation',
          'url': 'lmG76jG843Y',
          'playedSeconds': 0,
          'seen': false
        },
      18: {
          'id': '18',
          'sectionId': '3',
          'title': 'Estimates in Kanban',
          'url': 'I53tE5PXVXE',
          'playedSeconds': 0,
          'seen': false
        },
      19: {
          'id': '19',
          'sectionId': '4',
          'title': 'Prioritizing features using T-Shirt Sizing technique',
          'url': 'LJuYw4ynV1g',
          'playedSeconds': 0,
          'seen': false
        },
      20: {
          'id': '20',
          'sectionId': '4',
          'title': 'Communicating the estimates to business',
          'url': 'x9dJ9HRrQy8',
          'playedSeconds': 0,
          'seen': false
        },
      21: {
          'id': '21',
          'sectionId': '4',
          'title': 'Target VS Estimate Vs Commitment',
          'url': 'tYk06qB6rqo',
          'playedSeconds': 0,
          'seen': false
        }
    },
  }
};

const getInitialState = () => {
  const storedInitialState = localStorage.getItem('state');

  if (!storedInitialState) {
    localStorage.setItem('state', JSON.stringify(initialState));
    return initialState;
  } 

  return JSON.parse(storedInitialState);
}

const store = createContext(getInitialState());
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "UPDATE_TIME": {
        const id = action.id;
        let videos = state.courses.videos;
        videos[id] = {...videos[id], playedSeconds: action.seconds};
        const newState = {...state, courses: {...state.courses, videos: videos}};
        localStorage.setItem('state', JSON.stringify(newState));
        return newState;
      };
      case "MARK_AS_SEEN": {
        const id = action.id;
        let videos = state.courses.videos;
        videos[id] =  {...videos[id], seen: true };
        const newState = { ...state, courses: {...state.courses, videos: videos}};
        localStorage.setItem('state', JSON.stringify(newState));
        return newState;
      };
      case "MARK_AS_NOT_SEEN": {
        const id = action.id;
        let videos = state.courses.videos;
        videos[id] =  {...videos[id], seen: false };
        const newState = { ...state, courses: {...state.courses, videos: videos}};
        localStorage.setItem('state', JSON.stringify(newState));
        return newState;
      };
      default:
        throw new Error();
    }
  }, getInitialState());
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export { store, StateProvider };