import React, {useState, useRef, useCallback} from 'react';

import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css'
import '../css/chatBot.css'
import config from './Chatbot/config.js';
import MessageParser from './Chatbot/MessageParser.js';
import ActionProvider from './Chatbot/ActionProvider.js';

import ChatBotService from "../service/PlanningPoker/ChatBotService";

export default function ChatBotComponent({activeRoundObj, scaleValue, items}) {

    const [showBot, toggleBot] = useState(false);

    //any change in this property will trigger a re-render
    const [scrumMasterMessage, setScrumMasterMessage] = useState();

    const previousMessageRef = useRef([]);

    React.useEffect(() => {
        const updateScrumMasterMessage = (newMessages) => {
            if (!areArraysEqual(newMessages, previousMessageRef.current)) {
                setScrumMasterMessage(newMessages);
                previousMessageRef.current = newMessages;
            }
        };

        ChatBotService.buildScrumMasterEngine(activeRoundObj, scaleValue, updateScrumMasterMessage, items);
    }, [activeRoundObj, items]);

    // Function to compare two arrays deeply
    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    };


    const actionProviderMemoized = useCallback(
        (props) => <ActionProvider {...props} scrumMasterMessage={scrumMasterMessage} />,
        [scrumMasterMessage]
    );

    return (
        <div className="popup-container">
            {showBot && (

                <div className="app-chatbot-container">
                    <Chatbot
                        config={config}
                        messageParser={MessageParser}
                        actionProvider={actionProviderMemoized}
                    />
                </div>

            )}

            <button
                className="app-chatbot-button"
                onClick={() => toggleBot((prev) => !prev)}
            >
                <div>Ben</div>
                <svg viewBox="0 0 640 512" className="app-chatbot-button-icon">
                    <path
                        d="M192,408h64V360H192ZM576,192H544a95.99975,95.99975,0,0,0-96-96H344V24a24,24,0,0,0-48,0V96H192a95.99975,95.99975,0,0,0-96,96H64a47.99987,47.99987,0,0,0-48,48V368a47.99987,47.99987,0,0,0,48,48H96a95.99975,95.99975,0,0,0,96,96H448a95.99975,95.99975,0,0,0,96-96h32a47.99987,47.99987,0,0,0,48-48V240A47.99987,47.99987,0,0,0,576,192ZM96,368H64V240H96Zm400,48a48.14061,48.14061,0,0,1-48,48H192a48.14061,48.14061,0,0,1-48-48V192a47.99987,47.99987,0,0,1,48-48H448a47.99987,47.99987,0,0,1,48,48Zm80-48H544V240h32ZM240,208a48,48,0,1,0,48,48A47.99612,47.99612,0,0,0,240,208Zm160,0a48,48,0,1,0,48,48A47.99612,47.99612,0,0,0,400,208ZM384,408h64V360H384Zm-96,0h64V360H288Z"></path>
                </svg>

            </button>

        </div>
    );


}