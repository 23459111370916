import React, { useState } from "react";

import { Button, Grid, Typography, Box, Radio, FormControlLabel, FormControl, RadioGroup, Label } from "@mui/material";

export default function CardsForCategoryOfComplexity({
  giveEstimate,
  categoriesOfComplexity,CardForCategoryOfComplexity
}) {


  // State to track selected values for each category
  const [selectedValues, setSelectedValues] = useState({});

  // Handle radio button selection
  const handleRadioChange = (event, categoryId) => {
    setSelectedValues({
      ...selectedValues,
      [categoryId]: event.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = () => {

    // calculate the closest Fibonacci number to the sum of the selected values
    let closestFibonacciNumber = closestFibonacci(selectedValues);

    giveEstimate(closestFibonacciNumber);
  };

  function closestFibonacci(selectedValues) {


    // Step 1: Calculate the sum of the array
    const sum = sumSelectedValues(selectedValues);

    // Step 2: Generate Fibonacci numbers up to or slightly beyond the sum
    let fib1 = 0, fib2 = 1;
    let fibs = [fib1, fib2];

    while (fib2 < sum) {
      const nextFib = fib1 + fib2;
      fibs.push(nextFib);
      fib1 = fib2;
      fib2 = nextFib;
    }

    // Step 3: Find the closest Fibonacci number
    let closestFib = fibs[0];
    let minDiff = Math.abs(sum - fibs[0]);

    for (let i = 1; i < fibs.length; i++) {
      const diff = Math.abs(sum - fibs[i]);
      if (diff < minDiff) {
        minDiff = diff;
        closestFib = fibs[i];
      }
    }

    return closestFib;
  }

  function sumSelectedValues(selectedValues) {
    // Step 1: Initialize the sum to 0
    let sum = 0;

    // Step 2: Iterate over the values in the selectedValues object
    for (const key in selectedValues) {
      if (selectedValues.hasOwnProperty(key)) {
        sum += parseFloat(selectedValues[key]); // Convert value to a number and add it to the sum
      }
    }

    // Step 3: Return the sum
    return sum;
  }

  return (
    <Grid container sx={{ ml: 2, mt: { xs: 1, md: 10 } }}>
      {categoriesOfComplexity.map((row) => (
        <Grid item sx={{ mx: 1, my: 1 }} key={row.id}>

          <Box
              sx={{
                minWidth: "150px",
                minHeight: "97px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "secondary.main",
                borderRadius: "4px",
                cursor: "pointer",
                border: "2px solid",
                borderColor: "secondary.dark",
                boxShadow: 3,
                '&:hover': {
                  backgroundColor: "secondary.dark",
                  boxShadow: 6,
                }
              }}
          >

            <FormControl component="fieldset">
              <Typography variant="h4" sx={{ fontWeight: 200 }} color="success">
                {row.name}
              </Typography>
              <RadioGroup
                  aria-label="complexity"
                  name={`radio-buttons-${row.value}`}
                  defaultValue="0"
                  value={selectedValues[row.id] || ""}
                  onChange={(event) => handleRadioChange(event, row.id)}
              >
                <FormControlLabel
                    value="0"
                    control={<Radio sx={{ color: "success.main", '&.Mui-checked': { color: "success.main" } }} />}
                    label="None (0)"
                />
                <FormControlLabel
                    value="1"
                    control={<Radio sx={{ color: "success.main", '&.Mui-checked': { color: "success.main" } }} />}
                    label="Small (1)"
                />
                <FormControlLabel
                    value="2"
                    control={<Radio sx={{ color: "success.main", '&.Mui-checked': { color: "success.main" } }} />}
                    label="Low (2)"
                />
                <FormControlLabel
                    value="3"
                    control={<Radio sx={{ color: "success.main", '&.Mui-checked': { color: "success.main" } }} />}
                    label="Medium (3)"
                />
                <FormControlLabel
                    value="4"
                    control={<Radio sx={{ color: "success.main", '&.Mui-checked': { color: "success.main" } }} />}
                    label="High (4)"
                />
              </RadioGroup>
            </FormControl>

          </Box>

        </Grid>
      ))}

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
            variant='contained'
            color='success'
            onClick={handleSubmit}>
          Submit Estimate
        </Button>

      </Grid>

    </Grid>
  );
}
