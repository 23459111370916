import {api} from './http';
import {apiUrls} from '../config/Config';

class MailServiceClass {

    /**
     * Sends a contact email
     **/

    sendContactEmail(contactName, contactEmail, contactMessage) {
        return api.post(`${apiUrls.sendContactEmail}`, {
            name: contactName,
            email: contactEmail,
            message: contactMessage
        });
    }

}

const MailService = new MailServiceClass();

export default MailService;