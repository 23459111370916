import {useContext, useRef} from "react";
import {Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {ColorModeContext} from "../App";
import HomePageFooter from "../Components/HomePageFooter";
import HomePageTitle from "../Components/HomePageTitle";

import ThemedHeaderMenu from "../Components/ThemedHeaderMenu";

const TermsOfService = () => {
    let navigate = useNavigate();
    const colorMode = useContext(ColorModeContext);
    colorMode.switchToDarkMode();

    return (
        <Grid container bgcolor="#0F0819" height="100%" overflow="auto">
            <ThemedHeaderMenu/>
            <div>

                <Typography
                    component="h1"
                    variant="h1"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "36px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Terms and Conditions for Estimilo
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Introduction
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Estimilo.com (referenced below as Estimilo) is operated by Nicolae Andronic.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Disclaimer of Warranties
                </Typography>

                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Estimilo is provided on an "as is" and "as available" basis. Estimilo makes no
                    representations or warranties of any kind, express or implied, as to the operation of the site, or
                    the information, content, materials, or products included on this site. To the full extent
                    permissible by applicable law, Estimilo disclaims all warranties, express or implied, including
                    but not limited to, implied warranties of merchantability and fitness for a particular purpose.
                    Estimilo does not warrant that the site, its servers, or email sent from Estimilo are free
                    of viruses or other harmful components. Estimilo will not be liable for any damages of any kind
                    arising from the use of this site, including, but not limited to direct, indirect, incidental,
                    punitive, and consequential damages.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Limitation of Liability
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    In no event shall Estimilo be liable for any indirect, incidental, special, consequential or
                    punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other
                    intangible losses, resulting from (i) your access to or use of or inability to access or use
                    Estimilo; (ii) any conduct or content of any third party on Estimilo; (iii) any content
                    obtained from Estimilo; and (iv) unauthorized access, use or alteration of your transmissions or
                    content, whether based on warranty, contract, tort (including negligence) or any other legal theory,
                    whether or not we have been informed of the possibility of such damage.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Privacy Policy
                </Typography>

                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Your use of Estimilo is subject to our Privacy Policy, which is hereby incorporated into these
                    Terms by reference.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Termination
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Estimilo reserves the right, in its sole discretion, to terminate your access to all or part of
                    the site, with or without notice and for any reason, including, without limitation, breach of these
                    Terms. We also reserve the right to modify, suspend, or discontinue any aspect of the site at any
                    time, with or without notice.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Subscription, Renewal, Cancellation, and Refund
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Our services are provided either for free or on a subscription basis for a set term as selected and
                    specified at the time of your subscription. Unless either party gives notice of non-renewal,
                    subscriptions shall automatically renew for additional periods equal to the prior period. Notice of
                    non-renewal should be sent to hello[a with a monkey tail]estimilo[unique dot].com or you can manually cancel our services.
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Any price increase for the renewal period will be communicated to you in writing at least 30 days
                    before the end of the current subscription term. You will be responsible for paying all applicable
                    fees for the renewal period unless you cancel the subscription.
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    You may cancel our services at any time using the available tools or by sending a cancellation
                    request to hello[a with a monkey tail]estimilo[unique dot].com. If you are not satisfied with our services, you may request a full
                    refund for the paid month at the time of cancellation, and you will not be charged for the
                    subsequent month.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Customer Data
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    While using our services, you may submit certain data or content, such as texts, images, comments,
                    and votes. We reserve the right to use, process, access, display, copy, and store this customer data
                    solely to provide and improve our services, in accordance with applicable data protection laws,
                    these Terms, and our Privacy Policy.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Changes to Terms and Conditions
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    Estimilo reserves the right to modify these Terms and Conditions at any time. Any changes will
                    be posted on this page. Your continued use of the site following the posting of changes constitutes
                    your acceptance of such changes.
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Contact Information
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    For any questions regarding these Terms and Conditions, please contact us at hello[a with a monkey tail]estimilo[unique dot].com
                </Typography>

                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        color: "white",
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "28px",
                        pt: {xs: 1, md: 2, lg: 5},
                    }}
                >
                    Effective Date
                </Typography>
                <Typography
                    component="p"
                    variant="p"
                    sx={{
                        color: "white",
                        textAlign: "left",
                        my: {md: 1, lg: 1.5},
                        fontFamily: `'Roboto', sans-serif`,
                    }}
                >
                    These Terms and Conditions are effective as of 12 June 2024.
                </Typography>
            </div>


            <HomePageFooter/>

        </Grid>
    );
};

export default TermsOfService;
