import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Modal,
  Paper,
  Button,
  useTheme
} from '@mui/material';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import {
  dialogTitle,
  modalStyle,
  paperStyle,
  dialogInputLabel,
  dialogButtonStyle,
  inputStyle,
  titleContainer,
  exitIcon
} from './DialogWithInput.style';

const DialogWithInput = ({ open, handleClose, onSubmit, title, inputLabel, inputPlaceholder, buttonText, buttonVariant, validateInput }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);  // Delay focus to ensure the input is visible and interactable

      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleInputChange = (event) => {
    if (errorMessage) setErrorMessage("");
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevent default form submission behavior
    const error = validateInput ? validateInput(inputValue) : "";
    if (error) {
      setErrorMessage(error);
      return;
    }
    onSubmit(inputValue);
    handleCloseModal();  // Make sure to close the modal after submitting
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleCloseModal = () => {
    setInputValue("");
    setErrorMessage("");
    handleClose();
  };

  return (
      <Modal
          open={open}
          onClose={handleCloseModal}
          style={modalStyle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
        <Paper style={paperStyle} component="form" onSubmit={handleSubmit}>
          <div style={titleContainer}>
            <Typography
                style={dialogTitle}
                variant="h7"
                gutterBottom>{title}</Typography>
            <Button style={exitIcon} onClick={handleCloseModal}>
              <LogoutOutlined color='success'/>
            </Button>
          </div>
          <Typography
              style={dialogInputLabel}
              variant="p"
              gutterBottom>{inputLabel}</Typography>
          {errorMessage && (
              <Typography color="error" style={{ marginBottom: 8 }}>{errorMessage}</Typography>
          )}
          <input
              ref={inputRef}
              style={inputStyle(theme.palette.text.border, theme.palette.secondary.main, theme.palette.success.main, theme.palette.text.primary)}
              placeholder={inputPlaceholder}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}/>
          <Button
              variant={buttonVariant}
              color="success"
              type="submit"
              style={{
                ...dialogButtonStyle, 
                marginTop: 25, 
                marginBottom: 25, 
                textTransform: 'uppercase',
                fontWeight: 700,
                fontFamily: `'Roboto', sans-serif`
              }}>
            {buttonText}
          </Button>
        </Paper>
      </Modal>
  );
};

export default DialogWithInput;
