import React, {useState} from 'react';
import {TextField, Button, Container, Typography, Box} from '@mui/material';
import MailService from "../service/MailService";

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        MailService.sendContactEmail(name, email, message).then((result) => {
            //   return confirm message
            setStatus('Message sent successfully!');
            setName('');   // Clear the name field
            setEmail('');  // Clear the email field
            setMessage(''); // Clear the message field
        }).catch(
            error => {
                setStatus('Failed to send message. Please try again later.');
            }
        )
    };

    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} sx={{mt: 4}}>
                <TextField
                    label="Your Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <TextField
                    label="Your Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <TextField
                    label="Your message to us"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 1.5}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            bgcolor: "#BB86FC",
                            borderRadius: "10px",
                            color: "white",
                            fontWeight: 600,
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            minWidth: "230px",
                            fontFamily: `'Roboto', sans-serif`,
                        }}
                    >
                        Send
                    </Button>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 1.5}}>
                    {status && <Typography variant="body1" sx={{
                        color: "white",
                        fontSize: "18px",
                        fontFamily: `'Roboto', sans-serif`,
                        mt: 2,
                        lineHeight: 1.4,
                    }}>{status}</Typography>}
                </Box>
            </Box>
        </Container>
    );
};

export default ContactForm;
