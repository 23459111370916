import React from 'react';
import {
  Typography,
  Modal,
  Paper,
  Button
} from '@mui/material';
import { 
  dialogTitle, 
  modalStyle, 
  paperStyle, 
  dialogButtonStyle,
  buttonsContainer,
} from './DialogConfirm.style';

const DialogWithInput = ({open, onConfirm, onReject, title, confirmText, rejectText }) => {

  return (
    <Modal 
      open={open}
      onClose={onReject}
      style={modalStyle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">    
      <Paper style={paperStyle}>    
        <Typography 
            style={dialogTitle} 
            variant="h7" 
            gutterBottom>{title}</Typography>
        <div style={buttonsContainer}>
          <Button 
            variant='contained' 
            color="success" 
            type="submit"
            style={dialogButtonStyle}
            onClick={onConfirm}>
            {confirmText}
          </Button>
          <Button 
            variant='outlined' 
            color="success" 
            type="submit"
            style={dialogButtonStyle}
            onClick={onReject}>
            {rejectText}
          </Button>
        </div>
      </Paper>
    </Modal>)
};

export default DialogWithInput;