import React, { forwardRef } from 'react';

import { Grid, Typography } from "@mui/material";

const TitleWithPreTitle  = forwardRef(({ preTitle, title }, ref) =>  (
    <Grid container my={6} ref={ref}>
      <Grid item xs={12} mt={5}>
        <Typography component='p' variant='p' sx={{color: 'white', fontWeight: 600, textAlign: 'center'}}>
          {preTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {title.split('\n').map((line, index) => (
          <Typography key={index} component='h2' variant='h2' sx={{color: 'white', fontWeight: 800, textAlign: 'center', fontSize: '50px'}}>
            {line}
          </Typography>)
        )}
      </Grid>
    </Grid>
));

export default TitleWithPreTitle;