import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as BucketIcon } from '../../images/bucketIcon.svg';
import EstimateService from "../../service/EstimateService";
import {useTheme} from "@mui/material/styles";

export default function ComplexityBuckets({
                                            scaleValue,
                                            selectedEstimate,
                                          }) {
  const scaleBuckets = EstimateService.getScaleToUse(scaleValue).valuesMap;

    const theme = useTheme();

  return (
      <Grid container sx={{ ml: 2, mt: { xs: 1, md: 10 } }} justifyContent="center">
        {scaleBuckets.map((row) => (
            <Grid
                item
                sx={{ mx: 1, my: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                key={row.value}
            >
              <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: row.label === selectedEstimate ? "success.main" : "success",
                  }}
              >
                <BucketIcon style={{
                  width: '100%',
                  height: '100%' }} />
              </div>

              <Typography
                  variant="h4"
                  sx={{ fontWeight: 600, mt: 1 }}
                  color={row.label === selectedEstimate ? "success.main" : "success"}
                  align="center"
              >
                {row.label}
              </Typography>
            </Grid>
        ))}
      </Grid>
  );
}
