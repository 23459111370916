import React from 'react';

const MyCustomMessage = ({state}) => {
    const message = state.messages.findLast(item => item.type === "custom").message;
    return (
        <div style={{ backgroundColor: '#2596BE', color: 'white', padding: '10px', borderRadius: '5px' }}>
            <strong>Advice:</strong>
            <p>{message}</p>
        </div>
    );
};

export default MyCustomMessage;