// in config.js
import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import MyCustomMessage from "./MyCustomMessage";

const botName = 'Ben';

const config = {
    initialMessages: [createChatBotMessage(`Hi! I'm ${botName}, your Virtual Scrum Master.`)],
    botName: botName,
    customStyles: {
        botMessageBox: {
            backgroundColor: '#573f78ff',
        },
        chatButton: {
            backgroundColor: '#BB86FC',
        },
    },
    customComponents: {},
    customMessages: {
        custom: (props) => <MyCustomMessage {...props} />,
    },
};

export default config;