
import { Grid, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

const HomePageFooter = () => {
  const theme = useTheme();

  let navigate = useNavigate();

  return (
    <Grid container maxHeight={40}>
      <Grid item xs={1}/>
      <Grid item xs={10} borderTop={1} borderColor={theme.palette.primary.main}/>
      <Grid item xs={1}/>

      <Grid container  mt={2}>
        <Grid item xs={1}/>
        <Grid container item xs={10}>
          <Grid item xs={6} md={5}>
            <Typography variant='p' component='p' color='primary'>
              © 2024 Estimilo. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={0} md={2}/>
          <Grid item container xs={6} md={5} alignContent={{xs: 'flex-end', md: 'center'}} justifyContent='flex-end' direction={{xs: 'column', md: 'row'}}>
            <Button sx={{textTransform: 'none', textDecoration: 'underline', fontSize: '14px', display: 'flex', justifyContent: {xs: 'flex-end'} }} mx={2} onClick={() => {navigate("/privacy")}}>Privacy Policy</Button>
            <Button sx={{textTransform: 'none', textDecoration: 'underline', fontSize: '14px', display: 'flex', justifyContent: {xs: 'flex-end'} }} mx={2} onClick={() => {navigate("/terms")}}>Terms of Service</Button>
          </Grid>
        </Grid>
        <Grid item xs={1}/>
      </Grid>
    </Grid>
  );
}

export default HomePageFooter;