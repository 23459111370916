import React, { useState } from "react";
import { useNavigate  } from 'react-router-dom';

import { Container } from '@mui/material';

import EstimateSessionDataService from "../../service/EstimateSessionDataService";

import DialogWithInput from "../DialogWithInput/DialogWithInput";

export default function CreateParticipantAndNewEstimateSessionForm(props) {

  const [isModalOpen, setModalOpen] = useState(true);

  let navigate = useNavigate();


  const handleValidation = (input) => {
    if (!input.trim()) {
      return "Please enter your name"; // Basic non-empty validation
    }
    return "";
  };

  const handleSubmit = (participantName) => {

      //randomly generate an URL
      const generatedUrl = generateNumber(1000000, 10000000);

      initEstimateSessionAndParticipant(generatedUrl, participantName);

  }

    async function initEstimateSessionAndParticipant(generatedUrl, participantName) {



        try {
            // Call the API to create a new Estimate Session and wait for it to complete
            const sessionResult = await EstimateSessionDataService.createNewEstimateSession(generatedUrl, props.estimateSessionType);

            if (sessionResult) {
                // If the first API call was successful, change the Participant's role
                props.handleChangeParticipantRole('SCRUM_MASTER');

                // Call the API to create a new Participant after the Estimate Session is created
                const participantResult = await EstimateSessionDataService.createNewParticipantEstimateSession(generatedUrl, participantName, 'SCRUM_MASTER');

                // If the Participant creation is successful, handle the Participant's ID and name
                props.handleChangeParticipantId(participantResult.data);
                props.handleChangeParticipantName(participantName);

                // Navigate to the desired URL
                navigate(props.navigateTo + generatedUrl);
            }
        } catch (err) {
            // Handle any errors from the API calls (error handling already inside the service)
            console.error('Error initializing estimate session and participant:', err);
        }
    }



    /**
     * Calls the API for creating a new estimate session
     **/
    const createNewEstimateSession = (url) => {
      return EstimateSessionDataService.createNewEstimateSession(url, props.estimateSessionType);
    }

    /**
     * Generates a random number between min and max
     **/
    const generateNumber = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    return (
      <Container maxWidth="sm">
        <DialogWithInput 
          onSubmit={handleSubmit}
          title='Start an estimate session'
          inputLabel="What's your name?"
          inputPlaceholder='Your name'
          buttonText='Start Session'
          buttonVariant='contained'
          open={isModalOpen} 
          handleClose={() => {navigate('/');setModalOpen(!isModalOpen);}}
          validateInput={handleValidation}/>
      </Container>
    );

}