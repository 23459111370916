import React, { useState, useEffect, forwardRef } from "react";

import { Grid } from "@mui/material";
import TitleWithPreTitle from "./TitleWithPreTitle";
import YouTube from "react-youtube";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const FullWidthVideo = forwardRef((props, ref)  => {
  const video = { url: "KXMA-1QKO0c" };
  const { width } = useWindowDimensions();
  const opts = {
    width: "100%",
    height: width > 600 ? "800px" : "200px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 0,
      rel: 0,
    },
  };
  return (
    <Grid  ref={ref} container mx={{ xs: 1, md: 5 }}>
      <TitleWithPreTitle
        preTitle="We Offer"
        title={`Comprehensive Training Resources for You and Your Team`}
      />
      <Grid item xs={12} mt={3}>
        <YouTube videoId={video.url} opts={opts} />
      </Grid>
    </Grid>
  );
});

export default FullWidthVideo;
