export const videoPlayerStyle = {
  height: '90%',
  maxHeight: '600px',
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  flexDirection: 'column'
}

export const courseContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  overflowY: 'scroll',
  height: '100%'   
}