import React from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ProtectedRoute from './Components/ProtectedRoute';

import Config, {EstimateSessionType} from "./config/Config";
import CreateParticipantAndNewEstimateSessionForm
    from "./Components/genericTool/CreateParticipantAndNewEstimateSessionForm";
import EstimateSession from "./Pages/PlanningPoker/EstimateSession";
import EstimateSessionForComplexityBuckets from "./Pages/ComplexityBuckets/EstimateSessionForComplexityBuckets";
import HomePage from "./Pages/HomePage";
import Course from "./Pages/Course/Course";


import {ThemeProvider, createTheme} from "@mui/material/styles";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";
import LoginSuccess from "./Components/LoginSuccess";
import Dashboard from "./Pages/Dashboard";
import LoginPage from "./Pages/LoginPage";
import PremiumRoute from "./Components/PremiumRoute";
import CourseEstimatesInAgileSoftwareDevelopmentInPerson from "./Pages/Course/CourseEstimatesInAgileSoftwareDevelopmentInPerson";
import EstimateSessionHistory from "./Components/SessionHistory/EstimateSessionHistory";
import UserPreferences from "./Pages/UserPreferences";
import ManageTenant from "./Pages/ManageTenant";
import PremiumAdminRoute from "./Components/PremiumAdminRoute";

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    },
});

export default function App() {
    // App configuration
    const [participantRole, setParticipantRole] = React.useState();
    const [participantId, setParticipantId] = React.useState();
    const [participantName, setParticipantName] = React.useState();

    function handleChangeParticipantRole(newValue) {
        setParticipantRole(newValue);
    }

    function handleChangeParticipantName(newValue) {
        setParticipantName(newValue);
    }

    function handleChangeParticipantId(newValue) {
        setParticipantId(newValue);
    }

    const [mode, setMode] = React.useState("dark");

    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
            switchToDarkMode: () => {
                setMode(() => "dark");
            },
        }),
        []
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                typography: {
                    fontFamily: `'Kanit', sans-serif`,
                },
                palette: {
                    mode,
                    white: "#FFFFFF",
                    black600: "#0F0819",
                    black500: "#333130",
                    black400: "#3C3944",
                    black300: "#6F6C75",
                    black200: "#8A888F",
                    ...(mode === "light"
                        ? {
                            // palette values for light mode

                            primary: {main: "#242424"},
                            secondary: {main: "#F9F9F9"},
                            background: {
                                light: "#FFFFFF",
                            },
                            success: {
                                main: "#BB86FC",
                                contrastText: "#FFFFFF",
                            },
                            info: {
                                main: "#FFFFFF",
                                contrastText: "#4F46E5",
                            },
                            text: {
                                primary: "#242424",
                                secondary: "#666666",
                                border: "#2424242B",
                            },
                        }
                        : {
                            // palette values for dark mode
                            primary: {main: "#FFFFFF"},
                            secondary: {main: "#2d2d2d"},
                            background: {
                                light: "#161616",
                            },
                            success: {
                                main: "#BB86FC",
                                contrastText: "#FFFFFF",
                            },
                            info: {
                                main: "#161616",
                                contrastText: "#4F46E5",
                            },
                            text: {
                                primary: "#FFFFFF",
                                secondary: "#F4F4F4",
                            },
                        }),
                },
            }),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>


                        {/* Public Routes */}

                        <Route path="/" caseSensitive={false} element={<HomePage/>}/>

                        <Route
                            path="/login"
                            caseSensitive={false}
                            element={<LoginPage />}
                        />

                        <Route
                            path="/login-success"
                            caseSensitive={false}
                            element={<LoginSuccess />}
                        />

                        <Route
                            path="/privacy"
                            caseSensitive={false}
                            element={<PrivacyPolicy/>}
                        />

                        <Route
                            path="/terms"
                            caseSensitive={false}
                            element={<TermsOfService/>}
                        />

                        <Route
                            path="/course/EstimatesInAgileSoftwareDevelopmentInPerson"
                            caseSensitive={false}
                            element={<CourseEstimatesInAgileSoftwareDevelopmentInPerson/>}
                        />



                        {/* Protected Routes */}


                        <Route
                            path="/dashboard"
                            caseSensitive={false}
                            element={
                                <ProtectedRoute
                                    element={
                                        <Dashboard/>
                                    }
                                />
                            }
                        />

                        <Route
                            path="/planningPoker"
                            caseSensitive={false}
                            element={
                                <ProtectedRoute
                                    element={
                                        <CreateParticipantAndNewEstimateSessionForm
                                            handleChangeParticipantRole={handleChangeParticipantRole}
                                            handleChangeParticipantName={handleChangeParticipantName}
                                            handleChangeParticipantId={handleChangeParticipantId}
                                            navigateTo="/planningPoker/"
                                            estimateSessionType={EstimateSessionType.PLANNING_POKER}
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/planningPoker/:url"
                            caseSensitive={false}
                            element={
                                <ProtectedRoute
                                    element={
                                        <EstimateSession
                                            participantRole={participantRole}
                                            participantName={participantName}
                                            participantId={participantId}
                                            handleChangeParticipantName={handleChangeParticipantName}
                                            handleChangeParticipantId={handleChangeParticipantId}
                                            useVirtualScrumMaster={Config.USE_VIRTUAL_SCRUM_MASTER}
                                            scaleValue={Config.ESTIMATE_SCALE}
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/complexityBuckets"
                            caseSensitive={false}
                            element={
                                <PremiumRoute
                                    element={
                                        <CreateParticipantAndNewEstimateSessionForm
                                            handleChangeParticipantRole={handleChangeParticipantRole}
                                            handleChangeParticipantName={handleChangeParticipantName}
                                            handleChangeParticipantId={handleChangeParticipantId}
                                            navigateTo="/complexityBuckets/"
                                            estimateSessionType={EstimateSessionType.COMPLEXITY_BUCKETS}
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/complexityBuckets/:url"
                            caseSensitive={false}
                            element={
                                <PremiumRoute
                                    element={
                                        <EstimateSessionForComplexityBuckets
                                            participantRole={participantRole}
                                            participantName={participantName}
                                            participantId={participantId}
                                            handleChangeParticipantName={handleChangeParticipantName}
                                            handleChangeParticipantId={handleChangeParticipantId}
                                            useVirtualScrumMaster={Config.USE_VIRTUAL_SCRUM_MASTER}
                                            scaleValue={Config.ESTIMATE_SCALE}
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/estimateSessionHistory"
                            caseSensitive={false}
                            element={
                                <PremiumRoute
                                    element={
                                        <EstimateSessionHistory
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/userPreferences"
                            caseSensitive={false}
                            element={
                                <PremiumRoute
                                    element={
                                        <UserPreferences
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/manageTenant"
                            caseSensitive={false}
                            element={
                                <PremiumAdminRoute
                                    element={
                                        <ManageTenant
                                        />
                                    }
                                />
                            }
                        />

                        <Route
                            path="/course/EstimatesInAgileSoftwareDevelopment"
                            caseSensitive={false}
                            element={
                                <ProtectedRoute
                                    element={
                                        <Course/>
                                    }
                                />
                            }
                        />


                    </Routes>
                </Router>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
