import React from "react";

import {
  IconButton,
  Button,
  ListItem,
  List,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import ListCard from "../ListCard/ListCard";
import DialogConfirm from "../DialogConfirm/DialogConfirm"

import { isParticipantTheScrumMaster } from "../../Utils/Utils"

const StoriesList = ({initializeRound, stories, deleteStory,currentParticipantId, participants}) => {

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [selectedStoryToDelete, setSelectedStoryToDelete] = React.useState();

  const currentParticipant = participants && participants.filter(p => p.id === currentParticipantId);
  const currentParticipantRole = currentParticipant && currentParticipant[0]?.role;

  const handleDeleteStory = (id) => {
    deleteStory(id);
    setConfirmDeleteOpen(false);
  }

  return (
    <ListCard title='Items to estimate'>
      <List>
        {stories && stories.map(s => (
          <ListItem key={s.id} sx={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
              {!s.estimate ?
                  <div style={{display: 'flex', textOverflow: 'ellipsis', flex: 1, alignItems: 'center'}}>
                    {(isParticipantTheScrumMaster(currentParticipantRole)
                    ?
                    <IconButton color='success' onClick={() => {setSelectedStoryToDelete(s); setConfirmDeleteOpen(true)}} >
                      <DeleteIcon />
                    </IconButton >
                    : ''
                    )
                    }
                    <Typography component='p'>{s.name}</Typography>
                  </div>
                  :
                <div style={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
                  <Typography component='p'>
                    {s.name}
                  </Typography>
                  <Typography component='p' sx={{fontWeight: 'bold'}}>
                    {s.estimate}
                  </Typography>
                </div>
                }
              
            <div>
              {!s.estimate &&
                  (isParticipantTheScrumMaster(currentParticipantRole)
                          ?
                          <Button
                              variant='contained'
                              color='success'
                              onClick={() => {
                                initializeRound(s.id)
                              }}>
                            Estimate
                          </Button>
                          : ''
                  )
              }
            </div>
            <DialogConfirm
              title={`Are you sure you want to remove item: ${selectedStoryToDelete?.name}?`}
              open={confirmDeleteOpen}
              onConfirm={() => handleDeleteStory(selectedStoryToDelete?.id)}
              onReject={() => {setConfirmDeleteOpen(false)}}
              confirmText='YES'
              rejectText='NO'
            />
          </ListItem>
        ))}
      </List>
    </ListCard>
  );
}

export default StoriesList;