import React from "react";
import { Grid } from "@mui/material";
import bgImage from "../images/bgImage.png";

const FullWidthImage = () => (
  <Grid container px={{ xs: 1, md: 5 }} py={5} height={{ xs: 1 / 2, md: 1 }}>
    <img
      width="100%"
      height="100%"
      src={bgImage}
      alt="background with a lot o laptops nicely placed"
    />
  </Grid>
);

export default FullWidthImage;
