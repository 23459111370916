import { Grid, Typography } from "@mui/material";

const HomePageTitle = () => (
  <Grid container direction="row" mx={{ xs: 1, md: 5 }}>
    <Grid item xs={0} md={1} />
    <Grid item xs={12} md={10} justifyItems="center" mt={8} height={1 / 2}>
      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 800,
          textAlign: "center",
          fontSize: { xs: "31px", md: "47px", lg: "55px" },
        }}
      >
          Improve your software project estimates using our modern tools, services and training
      </Typography>
    </Grid>
    <Grid item xs={12} md={1} />

    <Grid item xs={0} md={1} />
    <Grid item xs={12} md={10} mb={6} height={1 / 2}>
        <Typography
            component="h5"
            variant="h5"
            sx={{
                color: "white",
                fontWeight: 275,
                textAlign: "center",
                fontSize: {xs: "16px", md: "24px"},
            }}
        >
            Estimilo provides a suite of tools, services, and specialized training to help you make your software development estimates more accurate and reliable.
        </Typography>
    </Grid>
    {/* <Grid item xs={12} md={1} height={{xs:1/2, md: 1}}/> */}
  </Grid>
);

export default HomePageTitle;
