import React from 'react';

import { Tooltip, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import DialogWithInput from "../DialogWithInput/DialogWithInput";

export default function CreateCategoryOfComplexityModal({onSubmit}) {

  const [isModalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleValidation = (input) => {
    if (!input.trim()) {
      return "Please enter some text"; // Basic non-empty validation
    }
    return "";
  };

  const handleSubmit = (value) => {

      onSubmit(value);
      setModalOpen(false);

  }

  return (
    <div style={{top: '30px', position: 'relative'}}>
      <Tooltip color="success" title="Add a Category of Complexity" aria-label="add">
        <Fab variant="extended" size="medium" style={{color: theme.palette.secondary}} onClick={handleOpen}>
          <AddIcon />
        </Fab>
      </Tooltip>

      <DialogWithInput
        onSubmit={handleSubmit}
        title='New Category of Complexity'
        inputLabel='Add Category of Complexity (max 5)'
        inputPlaceholder='Name of the Category of Complexity'
        buttonText='Save'
        buttonVariant='contained'
        open={isModalOpen}
        handleClose={handleClose}
        validateInput={handleValidation}/>
    </div>
  );
}
