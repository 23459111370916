import React, { useEffect, useState } from 'react';
import {Grid, Button, TextField, Typography, Box, Paper, useTheme} from '@mui/material';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import HomePageFooter from '../Components/HomePageFooter';
import logo from '../images/logo.png';
import {inputStyle} from "../Components/DialogWithInput/DialogWithInput.style"; // Import the logo

import GoogleButton from 'react-google-button'
import UserManagementService from "../service/UserManagementService";
import SystemConfig from "../config/SystemConfig";

const LoginPage = () => {


    const theme = useTheme();
    const [email, setEmail] = useState('');
    let navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const location = useLocation(); // Hook to detect the current URL

    // State to hold the extracted estimateSessionUrl
    const [estimateSessionUrl, setEstimateSessionUrl] = useState(null);


    // Check for specific error messages (e.g., UserNotFound)
    useEffect(() => {

        const urlParam = searchParams.get('estimateSessionUrl');
        if (urlParam) {
            setEstimateSessionUrl(urlParam); // Store the extracted parameter in state
        }

        const errorParam = searchParams.get("error");

        if (errorParam === "UserNotFound") {
            setErrorMessage("No account found with the provided Google account.");
        }
        // Handle general unexpected errors if redirected to the /error page
        else if (location.pathname === "/error") {
            setErrorMessage("An unexpected error occurred. Please try again later.");
        }
    }, [searchParams, location]);

    // Handle Google login redirect
    const handleGoogleLogin = () => {

        if (estimateSessionUrl) {
            // Set the estimateSessionUrl in a cookie to be retrieve in Backend and removed immediately after
            document.cookie = `estimateSessionUrl=${encodeURIComponent(estimateSessionUrl)}; path=/;`;
        }

        window.location.href = SystemConfig.getBaseUrl() + '/api' + "/oauth2/authorization/google"; // Redirect to Google SSO
    };

    // Handle Company SSO login
    const handleCompanySSOLogin = () => {

        const error = validateInput ? validateInput(email) : "";
        if (error) {
            setErrorMessage(error);
            return;
        }

        initLogin(email);
    };

    const validateInput = (input) => {
        if (!input.trim()) {
            return "Please enter some text."; // Basic non-empty validation
        }
        if (!input.includes('@')) {
            return "Input must include an '@' symbol."; // Custom validation logic
        }
        return "Internal error";
    };

    async function initLogin(loginEmail) {
        try {
            await doLogin(loginEmail);
        }
        catch (err) {
            console.error('Login Failed', err);
        }

    }



    const doLogin = (loginEmail) => {
        UserManagementService.doLogin(loginEmail).then((result) => {
        });
    }

    const handleInputChange = (event) => {
        if (errorMessage) setErrorMessage("");
        setEmail(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleCompanySSOLogin(event);
        }
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" bgcolor="#0F0819" height="100%">

            {/* Logo Section */}
            <Box textAlign="center" mb={2}>
                <img src={logo} alt="Estimilo Logo" style={{ height: '100px', marginBottom: '20px' }} />
            </Box>

            {/* Centered box with distinct background */}
            <Paper
                elevation={3}
                style={{
                    padding: '40px',
                    backgroundColor: '#1e1e2f',
                    borderRadius: '12px',
                    textAlign: 'center',
                    maxWidth: '500px',
                    width: '100%',
                }}
            >
                {/* Display error message if it exists */}
                {errorMessage && (
                    <Typography color="error" variant="body1" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                {/* Google SSO Section */}
                <Typography variant="h4" color="white" gutterBottom>
                    Sign in with Google
                </Typography>

                <Box display="flex" justifyContent="center" mb={4}>
                    <GoogleButton
                        type="dark"
                        onClick={handleGoogleLogin}
                    />
                </Box>


                {/* Divider */}
                <Box textAlign="center" my={4}>
                    <Typography variant="h6" color="white">
                        OR
                    </Typography>
                </Box>

                {/* Company SSO Section */}
                <Typography variant="h4" color="white" gutterBottom>
                    Sign in with own SSO
                </Typography>
                <input
                    style={inputStyle(theme.palette.text.border, theme.palette.secondary.main, theme.palette.success.main, theme.palette.text.primary)}
                    placeholder="Email"
                    value={email}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}/>


                <Button
                    variant="contained"
                    color="success"
                    onClick={handleCompanySSOLogin}
                    style={{marginTop: '20px'}}
                >
                    Sign in with Company SSO
                </Button>
            </Paper>

            {/* Footer */}
            <Box mt="auto" width="100%">
                <HomePageFooter/>
            </Box>
        </Grid>
    );
};

export default LoginPage;
