const width = window.innerWidth;

export const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
  
export const paperStyle = {
  width: width > 768 ? '50%' : '90%', 
  maxWidth: 600, 
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 10,
  outline: 'none',
}

export const titleContainer = {
  display: 'flex', 
  flexDirection: 'row', 
  width: '100%'
}

export const buttonsContainer = {
  display: 'flex',
  flexDirection: width > 400 ? 'row' : 'column',
  marginBottom: width > 400 ? 30 : 20,
  width: width > 400 ? '' : '100%',
  alignItems: 'center'
}

export const dialogTitle = {
  fontWeight: 600, 
  textAlign: 'center',
  margin: width > 400 ? 30 : 20,
  marginBottom: width > 400 ? 10 : 20,
  justifyContent: 'center'}

export const dialogButtonStyle = {
  fontWeight: 500,
  textTransform: 'none',
  fontSize: '12px',
  paddingLeft: width > 400 ? 60 : 0,
  paddingRight: width > 400 ? 60 : 0,
  width: width > 400 ? '' : '90%',
  margin: width > 400 ? 10 : 5
}
