import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

const ListCard = ({title, children}) => (
  <Paper sx={{my: 1, mx: 4, p: 2, py: 3 }}>
    <Typography 
      component='h5'
      sx={{fontWeight: 600}}>{title}</Typography>
    <Box>{children}</Box>
  </Paper>
);

export default ListCard;