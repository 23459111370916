import {api} from './http';
import {apiUrls} from '../config/Config';

import { jwtDecode } from 'jwt-decode';

class UserManagementServiceClass {

  /**
   * Logs in a user though the company's SSO
   * NOT USED FOR NOW
   **/
  doLogin(loginEmail) {
    return api.post(`${apiUrls.loginWithCompanySSO}`)
 }

    isUserAuthenticated = () => {
        // Check if the user is authenticated by checking the presence of token in sessionStorage
        const token = sessionStorage.getItem('jwtToken');

        if (!token) {
            return false;
        }
        return true;
    };


  /*
  * Check if the tenant is PERMANENT
   */
  isTenantPermanent= () => {
       // Retrieve the JWT token from sessionStorage
       const token = sessionStorage.getItem('jwtToken');
       if (token) {
           const decodedToken = jwtDecode(token);
           const tenantType = decodedToken.tenantType;

           return tenantType === "PERMANENT";

       }
   }

    isUserTenantAdmin = () => {

        // Retrieve the JWT token from sessionStorage
        const token = sessionStorage.getItem('jwtToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            const isUserTenantAdmin = decodedToken.isUserTenantAdmin;

            return isUserTenantAdmin;

        }
    }


}




const UserManagementService = new UserManagementServiceClass();

export default UserManagementService;