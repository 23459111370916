export const Config = {

  ESTIMATE_SCALE: "fibonacci",

  USE_VIRTUAL_SCRUM_MASTER: true,

  DEFAULT_PARTICIPANT_ROLE: "TEAM_MEMBER",

    /**
     * The map of scales to be used in estimating
     */
   scaleValuesMap: [
        {
            "scaleName": "fibonacci",
            "valuesMap": [
                {
                    "label": "0",
                    "value": 0
                },
                {
                    "label": "1",
                    "value": 1
                },
                {
                    "label": "2",
                    "value": 2
                },
                {
                    "label": "3",
                    "value": 3
                },
                {
                    "label": "5",
                    "value": 5
                },
                {
                    "label": "8",
                    "value": 8
                },
                {
                    "label": "13",
                    "value": 13
                },
                {
                    "label": "21",
                    "value": 21
                },
                {
                    "label": "40",
                    "value": 40
                },
                {
                    "label": "60",
                    "value": 60
                },
                {
                    "label": "100",
                    "value": 100
                },
                {
                    "label": "160",
                    "value": 160
                },
            ]
        },
        {
            "scaleName": "t-shirts",
            "valuesMap": [
                {
                    "label": "XS",
                    "value": 1
                },
                {
                    "label": "S",
                    "value": 2
                },
                {
                    "label": "M",
                    "value": 3
                },
                {
                    "label": "L",
                    "value": 5
                },
                {
                    "label": "XL",
                    "value": 8
                },
                {
                    "label": "XXL",
                    "value": 13
                }
            ]
        }
    ],
}

/**
* List of advices to be used randomly by the Virtual Scrum Master
*/
export const estimateAdvicesList = [
  "Consider also the complexity of the work",
  "Consider also the time needed to complete the work",
  "Consider also the difficulty of the work that needs to be done",
  "Take into consideration the risks/uncertainties of the work",
  "Take a look to the list of acceptance criteria for this specific item",
  "Does this item requires heavy setup in order to work on it?",
  "Take a look at the Definition of Done (DoD)",
  "Don't forget to consider also the testing effort",

    // Assumption Checking:
    "Are there any assumptions we’re making about this item?",
    "How do our assumptions affect the estimate?",
    //Technical Insight:
    "What technical challenges might we face with this item?",
    "Are there existing solutions or tools we can leverage for this item?",
    // Risk Identification:
    "What are the risks involved in implementing this?",
    "How might these risks affect our timeline and effort?",
    // Detail Probing:
    "Can you break down the steps involved in this item?",
    "Who has experience with this type of work, and what have you learned from past projects?",
    // Experience and Opinion Gathering:
    "What’s your gut feeling about the complexity of this item?",
    "From your experience, how long might such an item typically take?",
    // Resource and Capability Queries:
    "Do we have the right skills in the team to handle this item?",
    "Are there any capacity or resource constraints that we need to consider?"
];

export const VSM_CONSENSUS_MESSAGE_LIST = [
    "Good job, you've reached a consensus! The Scrum Master now needs to accept the estimate.",
    "Does everyone agree with the proposed estimate?",
    "If not, what are the concerns, and how can we address them?"
];

export const VSM_NO_ESTIMATES_GIVEN_MESSAGE_LIST = [
    "Can someone explain what this item entails?",
    "Are there any dependencies or prerequisites for this item?",
    "Do we have all the information needed to estimate this item?"
];

export const VSM_BECOMES_VISIBLE =  [
    "Hi! I'm your Virtual Scrum Master, Ben!"
]

export const apiUrls = {
  createNewEstimateSession: 'session',
  retrieveEstimateSession: 'session',
  retrieveAllParticipantsFromSession: 'session/retrieveAllParticipantsFromSession',
  createNewParticipantToEstimateSession: 'participant/addToSession',
  deleteParticipantFromEstimateSession: 'participant/deleteFromSession',
  heartbeatUrl: '/heartbeat',
  retrieveAllItemsFromSession: 'session/retrieveAllItemsFromSession',
  retrieveAllEstimateSessions: 'sessions',
  createNewItemToEstimateSession: 'item/addToSession',
  retrieveItemsFromJira: 'item/retrieveFromJira',
  createNewCategoryOfComplexityToEstimateSession: 'categoryOfComplexity/addToSession',
  retrieveAllCategoriesOfComplexityFromSession: 'session/retrieveAllCategoriesOfComplexityFromSession',
  initializeRound: 'round/initializeRound',
  giveEstimate: 'round/giveEstimate',
  acceptConsensusEstimate: 'round/acceptConsensusEstimate',
  deleteFromSession: 'item/deleteFromSession',
  deleteCategoryOfComplexityFromSession: 'categoryOfComplexity/deleteFromSession',
  deleteParticipant: 'round/deleteParticipant',
  retrieveActiveRoundFromSession: 'round/retrieveActiveRoundFromSession',
  loginWithCompanySSO: 'management/users/login',
  retrieveLoggedInUserInfo: 'management/user',
  deleteLoggedInUser: 'management/user',
  getAllUsers: 'management/users',
  createNewUser: 'management/user',
  deleteUser: 'management/user',
  getUserPreferences: 'management/user/preferences',
  saveUserPreference: 'management/user/preference',
  sendContactEmail: '/email/send',
}

export const EstimateSessionType = {
    PLANNING_POKER: 'PLANNING_POKER',
    COMPLEXITY_BUCKETS: 'COMPLEXITY_BUCKETS',
    WHITE_ELEPHANT_SIZING: 'WHITE_ELEPHANT_SIZING',
    OUIJA_BOARD_ESTIMATE: 'OUIJA_BOARD_ESTIMATE'
};

export const UserPreferenceKey  = {
    JIRA_BASE_URL: 'JIRA_BASE_URL',
    JIRA_EMAIL: 'JIRA_EMAIL',
    JIRA_API_TOKEN: 'JIRA_API_TOKEN',
    JIRA_PROJECT_KEY: 'JIRA_PROJECT_KEY'
};


export default Config;