import React from "react";
import { useNavigate } from 'react-router-dom';

import {
  IconButton,
  ListItem,
  List,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import ListCard from "../ListCard/ListCard";
import DialogConfirm from "../DialogConfirm/DialogConfirm"

import { isParticipantTheScrumMaster } from "../../Utils/Utils"

const ParticipantList = ({deleteParticipant, participants, currentParticipantId, navigateToURL}) => {

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [participantIdToDelete, setParticipantIdToDelete] =  React.useState();
  let navigate = useNavigate();

  const currentParticipant = participants && participants.filter(p => p.id === currentParticipantId);
  const currentParticipantRole = currentParticipant && currentParticipant[0]?.role;


  //handles opening of the Delete Participant dialog
  const handleDeleteParticipantDialogOpen = (id) => {
    setConfirmOpen(true);
    setParticipantIdToDelete(id);
  }

  const handleDeleteParticipant = () => {
    deleteParticipant(participantIdToDelete); 
    setConfirmOpen(false);
    if(currentParticipantId === participantIdToDelete) {
      navigate("/planningPoker");
      navigate(navigateToURL);
    }
  }

  return (
    <ListCard title='Estimators'>
      <List>
        {participants.map((p) => (
          <ListItem key={p.id} sx={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{textOverflow: 'ellipsis'}}>
              {(isParticipantTheScrumMaster(currentParticipantRole)
                  ?
                  <IconButton color='success' onClick={() => handleDeleteParticipantDialogOpen(p.id)}>
                    <DeleteIcon />
                  </IconButton>
                  : ''
              )}
              {currentParticipantId === p.id ? <b> {p.name} </b>: p.name }
              {isParticipantTheScrumMaster(p.role) ?'(Scrum Master)': '' }
            </div>
          </ListItem>
        ))}
      </List>
      <DialogConfirm
        title={participantIdToDelete === currentParticipantId ? 'Are you sure you want to remove yourself?' : 'Are you sure you want to delete this estimator?' }
        open={confirmOpen}
        onConfirm={handleDeleteParticipant}
        onReject={() => {setConfirmOpen(false)}}
        confirmText='YES'
        rejectText='NO'
      />
    </ListCard>
  );
}

export default ParticipantList;