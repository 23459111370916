export const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const paperStyle = {
  width: '80%', 
  maxWidth: 600, 
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 10,
  outline: 'none',
}

export const titleContainer = {
  display: 'flex', 
  flexDirection: 'row', 
  width: '100%'
}

export const dialogTitle = {
  fontWeight: 600, 
  textAlign: 'center',
  marginTop: 15,
  marginBottm: 10,
  justifyContent: 'center',
  flex: 9,
  marginLeft: '64px',
}

export const exitIcon = {
  alignSelf: 'flex-end',
}

export const dialogInputLabel = {
  alignSelf: 'center',
  fontWeight: 600, 
  width: '90%',
  marginTop: 30,
  fontSize: 14,
}

export const dialogButtonStyle = {
  fontWeight: 500,
  textTransform: 'none',
  width: 200,
  maxWidth: '80%',
}

export const inputStyle = (borderColor, backgroundColor, outlineColor, textColor) => ({
    alignSelf: 'center',
    width: '90%',
    marginLeft: 7,
    marginTop: '0.3rem',
    height: '2rem',
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 5,
    borderColor: borderColor,
    borderStyle: 'solid',
    backgroundColor: backgroundColor,
    outlineColor: outlineColor,
    color: textColor
});