import React, { useCallback, useEffect, useState } from "react";

import { List, ListItem, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ListCard from "../ListCard/ListCard";
import { isParticipantTheScrumMaster } from "../../Utils/Utils";

export default function RoundParticipantList({
  participants,
  currentParticipantId,
  deleteParticipant,
  currentParticipantRole,
}) {
  const [allEstimates, setAllEstimates] = useState(false);

  //returns true if all estimates were given, false otherwise
  const allEstimatesGiven = useCallback(() => {
    let result = true;
    participants.forEach((row) => {
      if (row.estimate === null) result = false;
    });
    return result;
  }, [participants]);

  useEffect(() => {
    setAllEstimates(allEstimatesGiven());
  }, [allEstimatesGiven]);

  const renderEstimate = (participant) => {
    if (allEstimates) {
      return <div>{participant.estimate}</div>;
    } else if (
      participant.estimate !== null &&
      currentParticipantId === participant.participant.id
    ) {
      return <div>{participant.estimate}</div>;
    } else if (participant.estimate !== null) {
      return <div>GIVEN</div>;
    }
  };

  return (
    <ListCard>
      <List>
        {participants.map((item) => (
          <ListItem
            key={item.participant.id}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ textOverflow: "ellipsis" }}>
              {isParticipantTheScrumMaster(currentParticipantRole) ||
              currentParticipantId === item.participant.id ? (
                <IconButton
                  color="success"
                  onClick={() => deleteParticipant(item.participant.id)}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                ""
              )}
              {currentParticipantId === item.participant.id ? (
                <b> {item.participant.name} </b>
              ) : (
                item.participant.name
              )}
            </div>
            <div>{renderEstimate(item)}</div>
          </ListItem>
        ))}
      </List>
    </ListCard>
  );
}
