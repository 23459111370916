import React, { useState, useContext } from 'react';

import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { store } from '../../store';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return [
    width,
    height
  ];
}

const CustomizedListItem = ({section, videos, setCurrentVideo, currentVideo}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const [width] = getWindowDimensions();
  
  return (
    <Box style={{backgroundColor: theme.palette.background.light}}>
      <ListItem button key={section.id} onClick={() => setIsOpen(!isOpen)}>
        <ListItemText style={{color: theme.palette.primary.main}} primary={section.name} />
        {isOpen ? <ExpandLess style={{color: theme.palette.primary.main}}/> : <ExpandMore style={{color: theme.palette.primary.main}}/>}
      </ListItem>
      <Collapse
        in={isOpen}
        timeout='auto'
        unmountOnExit
      >
      <List component='li' disablePadding key={section.id}>
        {videos.map(child => {
          return (
            <ListItem button key={child.id} style={{backgroundColor: child.id === currentVideo.id ? 'darkgrey' : theme.palette.secondary.main}}>
              <ListItemText 
                primary={child.seen ? `${child.title} ✅` : child.title}
                style={{color: theme.palette.primary.main}}
                onClick={(e) => {
                  setCurrentVideo(child); 
                  if(width < 600) {
                    setIsOpen(false);
                  }
                  }} />
            </ListItem>
          );
        })}
      </List>
    </Collapse>
    <Divider />
    </Box>
  );
}

const CourseContent = ({setCurrentVideo, currentVideo}) => {
  const dataStore = useContext(store);
  const data = dataStore.state;
  return (
    <Box 
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
      }}>
      <List component='nav' aria-labelledby='nested-list-subheader' >
        {data.courses.sections.map(section => {
          const videoKeys = Object.keys(data.courses.videos);
          let videos = [];
          for(let i = 0; i < videoKeys.length; i++) {
            if (data.courses.videos[videoKeys[i]].sectionId === section.id) {
              videos.push(data.courses.videos[videoKeys[i]]);
            }
          }
          return (
            <CustomizedListItem 
              key={section.id}
              section={section}
              videos={videos} 
              setCurrentVideo={setCurrentVideo}
              currentVideo={currentVideo}/>
          )
        })}
      </List>
    </Box>
  );
};

export default CourseContent;