import React from 'react';
import UserManagementService from "../service/UserManagementService";

// Component to hide elements based on user authentication and tenant status
const PremiumContent = ({ children }) => {

    // Check if the user is authenticated and if the tenant is permanent
    if (UserManagementService.isUserAuthenticated() && UserManagementService.isTenantPermanent()) {
        // Render children (content) if user is authenticated and tenant is permanent
        return <>{children}</>;
    }

    // Render nothing (null) if the user is not authenticated or tenant is not permanent
    return null;
};

export default PremiumContent;
